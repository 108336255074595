import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { About } from "./About";
import { Videos } from "./Videos";
import { Writings } from "./Writings";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/videos" element={<Videos />}></Route>
      <Route path="/writings" element={<Writings />}></Route>
    </Routes>
  </BrowserRouter>,
);
