import { useNavigate, useLocation } from "react-router-dom";
import {
  FaGithub,
  FaTwitter,
  FaLinkedin,
  FaPhotoVideo,
  FaPenAlt,
  FaAddressCard,
  FaHome,
} from "react-icons/fa";

export const App = () => {
  return (
    <>
      <div className="max-w-2xl mx-auto p-8 min-h-[95vh]">
        <div className="flex flex-col">
          <TopBar />
          <div className="flex gap-x-4">
            <img
              className="rounded-lg w-[120px] h-[120px]"
              src="/prof-pic.png"
              alt="profile picture"
            />
            <div className="flex flex-col">
              <div className="text-3xl font-bold text-center mb-4 text-start">
                Hey there, I'm Alec
              </div>
              <div className="text-lg text-center mb-4 text-start">
                I'm a fullstack software engineer turned solopreneur. Right now
                I'm building{" "}
                <a
                  className="text-violet-500 hover:underline cursor-pointer"
                  href="https://devlist.co/?utm_source=acharbohno.com"
                >
                  Dev List
                </a>
                , the #1 list for freelance devs.
              </div>
            </div>
          </div>

          <div className="my-4 text-lg font-bold">📍 California, USA</div>
          <div className="flex gap-x-1">
            {" "}
            <a
              href="https://github.com/acharb"
              className={`cursor-pointer hover:bg-violet-800 hover:border-gray-300 py-1 px-1 rounded-lg`}
            >
              <FaGithub size={24} />
            </a>
            <a
              href="https://x.com/acharbohno"
              className={`cursor-pointer hover:bg-violet-800 hover:border-gray-300  py-1 px-1 rounded-lg`}
            >
              <FaTwitter size={24} />
            </a>
            <a
              href="https://www.linkedin.com/in/aleccharbonneau/"
              className={`cursor-pointer hover:bg-violet-800 hover:border-gray-300  py-1 px-1 rounded-lg`}
            >
              <FaLinkedin size={24} />
            </a>
          </div>

          <div className="my-12">
            <h1 className="my-4 tracking-tight text-2xl font-bold">
              Latest Projects
            </h1>

            <a
              href="https://devlist.co/?utm_source=acharbohno.com"
              className="my-4 flex flex-col text-gray-300"
            >
              <div className="my-2 flex flex-col-reverse sm:flex-row justify-between hover:text-white cursor-pointer hover:underline">
                <div className="flex flex-col">
                  <div className="text-white font-bold">
                    Dev List (5k+ subscribers and counting)
                  </div>
                  <div>#1 List for freelance devs</div>
                </div>

                <div className="text-violet-500">April 2024</div>
              </div>
              <div className="flex gap-x-2">
                <div className="bg-violet-950 text-violet-400 rounded-xl px-2 w-[fit-content]">
                  Node.js
                </div>
                <div className="bg-violet-950 text-violet-400 rounded-xl px-2 w-[fit-content]">
                  Beehiiv
                </div>
              </div>
            </a>
            <a
              href="https://github.com/stellar/typescript-wallet-sdk"
              className="my-4 flex flex-col text-gray-300 hover:text-white cursor-pointer"
            >
              <div className="my-2 flex flex-col-reverse sm:flex-row justify-between hover:text-white cursor-pointer hover:underline">
                <div>
                  <div className="text-white font-bold">
                    Stellar - Typescript Wallet SDK
                  </div>
                  <div>SDK for building wallets on the Stellar Blockchain</div>
                </div>

                <div className="text-violet-500">February 2023</div>
              </div>
              <div className="flex gap-x-2">
                <div className="bg-violet-950 text-violet-400 rounded-xl px-2 w-[fit-content]">
                  JavaScript
                </div>
                <div className="bg-violet-950 text-violet-400 rounded-xl px-2 w-[fit-content]">
                  TypeScript
                </div>
                <div className="bg-violet-950 text-violet-400 rounded-xl px-2 w-[fit-content]">
                  Node.js
                </div>
              </div>
            </a>
            <a
              href="https://github.com/stellar/freighter"
              className="my-4 flex flex-col text-gray-300 hover:text-white cursor-pointer"
            >
              <div className="my-2 flex flex-col-reverse sm:flex-row justify-between hover:text-white cursor-pointer hover:underline">
                <div className="flex flex-col max-w-[100%] sm:max-w-[70%]">
                  <div className="text-white font-bold">
                    Stellar - Freighter
                  </div>
                  <div>
                    Browser extension for Stellar blockchain (think Metamask but
                    for Stellar)
                  </div>
                </div>

                <div className="text-violet-500">January 2022</div>
              </div>
              <div className="flex gap-x-2">
                <div className="bg-violet-950 text-violet-400 rounded-xl px-2 w-[fit-content]">
                  TypeScript
                </div>
                <div className="bg-violet-950 text-violet-400 rounded-xl px-2 w-[fit-content]">
                  React
                </div>
                <div className="bg-violet-950 text-violet-400 rounded-xl px-2 w-[fit-content]">
                  Browser Extension
                </div>
              </div>
            </a>
            <a
              href="https://github.com/stellar/starlight"
              className="my-4 flex flex-col text-gray-300 hover:text-white cursor-pointer"
            >
              <div className="my-2 flex flex-col-reverse sm:flex-row justify-between hover:text-white cursor-pointer hover:underline">
                <div className="flex flex-col max-w-[100%] sm:max-w-[70%]">
                  <div className="text-white font-bold">
                    Stellar - Starlight
                  </div>
                  <div>
                    First layer 2 implementation for Stellar (think Bitcoin's
                    lightning network but for Stellar)
                  </div>
                </div>

                <div className="text-violet-500">May 2021</div>
              </div>
              <div className="flex gap-x-2">
                <div className="bg-violet-950 text-violet-400 rounded-xl px-2 w-[fit-content]">
                  Golang
                </div>
              </div>
            </a>
            <a
              href="https://www.assurely.com/"
              className="my-4 flex flex-col text-gray-300 hover:text-white cursor-pointer"
            >
              <div className="my-2 flex flex-col-reverse sm:flex-row justify-between hover:text-white cursor-pointer hover:underline">
                <div className="flex flex-col">
                  <div className="text-white font-bold">Assurely</div>
                  <div>Insurtech platform</div>
                </div>

                <div className="text-violet-500">September 2019</div>
              </div>
              <div className="flex gap-x-2">
                <div className="bg-violet-950 text-violet-400 rounded-xl px-2 w-[fit-content]">
                  Ruby on Rails
                </div>
              </div>
            </a>
            <a
              href="https://www.circle.com/en/"
              className="my-4 flex flex-col text-gray-300 hover:text-white cursor-pointer"
            >
              <div className="my-2 flex flex-col-reverse sm:flex-row justify-between hover:text-white cursor-pointer hover:underline">
                <div className="flex flex-col">
                  <div className="text-white font-bold">
                    Circle - Equity Crowfunding Arm (Formerly SeedInvest)
                  </div>
                  <div>Equity Crowdfunding platform</div>
                </div>

                <div className="text-violet-500">March 2019</div>
              </div>
              <div className="flex gap-x-2">
                <div className="bg-violet-950 text-violet-400 rounded-xl px-2 w-[fit-content]">
                  Python
                </div>
                <div className="bg-violet-950 text-violet-400 rounded-xl px-2 w-[fit-content]">
                  JavaScript
                </div>
                <div className="bg-violet-950 text-violet-400 rounded-xl px-2 w-[fit-content]">
                  Angular
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <BottomBar />
    </>
  );
};

export const BottomBar = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center py-4">
        <div className="flex gap-x-8 w-[fit-content]">
          {" "}
          <a
            href="https://github.com/acharb"
            className={`cursor-pointer hover:bg-violet-800 hover:border-gray-300 py-1 px-1 rounded-lg`}
          >
            <FaGithub size={30} />
          </a>
          <a
            href="https://x.com/acharbohno"
            className={`cursor-pointer hover:bg-violet-800 hover:border-gray-300  py-1 px-1 rounded-lg`}
          >
            <FaTwitter size={30} />
          </a>
          <a
            href="https://www.linkedin.com/in/aleccharbonneau/"
            className={`cursor-pointer hover:bg-violet-800 hover:border-gray-300  py-1 px-1 rounded-lg`}
          >
            <FaLinkedin size={30} />
          </a>
        </div>
      </div>
    </div>
  );
};

export const TopBar = () => {
  const navigate = useNavigate();
  const { pathname: path } = useLocation();

  return (
    <div className="flex gap-x-8 justify-center pb-12 pt-4">
      <a
        onClick={() => {
          navigate("/");
        }}
        className={`${path === "/" ? "bg-gray-800" : ""} cursor-pointer hover:bg-gray-800 hover:border-gray-300  py-2 px-4 rounded-lg`}
      >
        <FaHome size={28} />
      </a>
      <a
        onClick={() => {
          navigate("/about");
        }}
        className={`${path === "/about" ? "bg-gray-800" : ""} cursor-pointer hover:bg-gray-800 hover:border-gray-300  py-2 px-4 rounded-lg`}
      >
        <FaAddressCard size={28} />
      </a>
      <a
        onClick={() => {
          navigate("/videos");
        }}
        className={`${path === "/videos" ? "bg-gray-800" : ""} cursor-pointer hover:bg-gray-800 hover:border-gray-300  py-2 px-4 rounded-lg`}
      >
        <FaPhotoVideo size={28} />
      </a>
      <a
        onClick={() => {
          navigate("/writings");
        }}
        className={`${path === "/writings" ? "bg-gray-800" : ""} cursor-pointer hover:bg-gray-800 hover:border-gray-300  py-2 px-4 rounded-lg`}
      >
        <FaPenAlt size={28} />
      </a>
    </div>
  );
};
