import { TopBar, BottomBar } from "./App";

export const Videos = () => {
  return (
    <>
      <div className="max-w-2xl mx-auto p-8 min-h-[95vh]">
        <div className="flex flex-col gap-y-4">
          <TopBar />
          <div className="text-2xl font-bold">
            Starlight: A Layer 2 Payment Channel Protocol for Stellar
          </div>

          <div className="w-[300px] h-[200px] sm:w-[560px] sm:h-[315px]">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/LI_M6rWPCgQ?si=bFpsToMe-LSUvBBN"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>

          <div className="text-gray-300">Nov 11, 2021</div>
          <div className="text-gray-300">
            A talk{" "}
            <a className="hover:underline" href="https://leighmcculloch.com/">
              Leigh McCulloch
            </a>{" "}
            and I gave for our work building a layer 2 solution for the Stellar
            Blockchain network.
          </div>
        </div>
      </div>
      <BottomBar />
    </>
  );
};
