import { TopBar, BottomBar } from "./App";

export const About = () => {
  return (
    <>
      <div className="max-w-2xl mx-auto p-8 min-h-[95vh]">
        <div className="flex flex-col gap-y-12 text-lg">
          <TopBar />
          <div className="text-xl font-bold">Hi, I'm Alec.</div>
          <div>
            I'm currently living in California. I grew up in Ohio, and studied
            Mechanical Engineering at{" "}
            <a
              className="text-violet-700 hover:underline"
              href="https://www.cornell.edu/"
            >
              Cornell
            </a>
            .
          </div>
          <div>
            Since 2016 I've worked as a full stack software engineer in the tech
            industry. I worked in fintech and blockchain startups in New York
            City and San Francisco.
          </div>
          <div>
            In 2022 I decided to take the leap and start working for myself.
            Currently I run the only (that I know of) aggregated list of top
            freelance jobs for devs -{" "}
            <a
              href="https://devlist.co/?utm_source=acharbohno.com"
              className="hover:underline text-violet-700"
            >
              Dev List.
            </a>
          </div>
          <div>
            My life goal is to make a living doing meaningful work, travel, and
            connect with others.
          </div>
        </div>
      </div>
      <BottomBar />
    </>
  );
};
